export default {
    namespaced: true,
    state: {
        selectedUri: undefined,
    },
    getters: {},
    mutations: {
        setSelectedUri(state, uri) {
            state.selectedUri = uri;
        },
    },
    actions: {
        async selectUri({commit}, uri) {
            commit('setSelectedUri', uri);
        }
    },
}
  