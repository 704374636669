import {createRouter, createWebHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from "@/views/DashboardView.vue";
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: Home,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/users',
                name: 'accounts',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "accounts" */ '../views/accounts/Accounts.vue'),
            },
            {
                path: '/create-accounts',
                name: 'create-accounts',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "accounts" */ '../views/accounts/CreateAccounts.vue'),
            },
            {
                path: '/balance',
                name: 'balance',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "balance" */ '../views/Balance.vue'),
            },
            {
                path: '/topupssettlements',
                name: 'topupssettlements',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "settlement" */ '../views/settlements/Settlement.vue'),
            },
            {
                path: '/create-settlement',
                name: 'create-settlement',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "settlement" */ '../views/settlements/CreateSettlement.vue'),
            },
            {
                path: '/edit-settlement',
                name: 'edit-settlement',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "settlement" */ '../views/settlements/EditSettlements.vue'),
            },
            {
                path: '/reports',
                name: 'reports',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
            },
            {
                path: '/webhooks',
                name: 'webhooks',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "merchant-hooks" */ '../views/merchanthooks/MerchantHooks.vue'),
            },
            {
                path: '/create-merchant-hooks',
                name: 'create-merchant-hooks',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "create-merchant-hooks" */ '../views/merchanthooks/CreateMerchantHooks.vue')
            },
            {
                path: '/currencies',
                name: 'currencies',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "currency" */ '../views/currency/Currency.vue')
            },
            {
                path: '/create-currency',
                name: 'create-currency',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "create-currency" */ '../views/currency/CreateCurrency.vue')
            },
            {
                path: '/bankcryptoaccounts',
                name: 'bankcryptoaccounts',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/bankcryptoaccounts/Bankcryptoaccounts.vue')
            },
            {
                path: '/create-wallet',
                name: 'create-wallet',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '../views/main/CreateWallet.vue')
            },
            {
                path: '/create-bankcryptoaccounts',
                name: 'create-bankcryptoaccounts',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/bankcryptoaccounts/CreateBankcryptoaccounts.vue')
            },
            {
                path: '/manualcashouts',
                name: 'manualcashouts',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/cashout/ManualCashOuts.vue')
            },  
            {
                path: '/merchants',
                name: 'merchants',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/merchants/Merchant.vue')
            },         
            {
                path: '/create-merchant',
                name: 'create-merchants',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/merchants/CreateMerchant.vue')
            },         
            {
                path: '/edit-merchant',
                name: 'edit-merchant',
                meta: {
                    requiresAuth: true,
                },
                component: () => import(/* webpackChunkName: "bankcryptoaccounts" */ '@/views/merchants/EditMerchant.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
import {AuthUser} from "@/models/user"
import store from '@/store/index'
router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_BYPASS_AUTH === 'true') {
        next();
    } else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // if this route requires auth, check if logged in
            if (!localStorage.getItem('access_token')) {
                next({name: 'login'}); // redirect to the login page if not allowed
            } else {
                let access_token = localStorage.getItem('access_token');
                let id_token = localStorage.getItem('id_token');
                console.log(access_token,id_token)
                if (id_token) {
                    store.commit('setAuthUser', new AuthUser(access_token, id_token));
                    console.log('AUTHUSER',store.state.authUser)
                }
                next(); // go to wherever I'm going
            }
        } else {
            next(); // does not require auth, make sure to always call next() to avoid issues
        }
    }
});

export default router
