import Toast, {useToast} from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
    // You can set your default options here
};

const ToastPlugin = {
    install: (app) => {
        app.use(Toast, options)
        app.config.globalProperties.$toast = useToast();
        window.toast = useToast();
    },
};

export default ToastPlugin;