<template>
  <br>
  <h1>Wallets</h1>
  <br>
  <v-row>
    <div class="box" v-for="item in wallets"
        :key="item.id">
        <v-btn icon="mdi-wallet-bifold-outline" class="ml-3 mt-3 icon" />
        <h1> {{item.currency.name.toUpperCase()}}</h1>
        <p>Balance: {{item.credit}}</p>
    </div>
    <v-dialog 
        v-model="openDialog"
        width="1024"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props" class="box" @click="createWallet()">
          <button>
            <img :src="require('@/assets/add_icon.svg')" alt="">
          </button>
          <h1>Open</h1>
        </div>
      </template>
    </v-dialog>
    </v-row>
    <v-divider></v-divider>
    <br>
    <h1>Transactions</h1>
    <br>
    <paginated-datatable 
        :headers="headers_merch_adm"
        :filters="filters"
        api_root="/ledger"
    >
        <template #[`toolbar-buttons`]>
          <export-excel-vue :json_fields="json_fields" :routeName="routeName = '/ledger/by_auth'" :excelFileName="'transactions'"></export-excel-vue>
        </template>
        <template #[`filters`]>
            <v-text-field label="Merchant Transaction ID" v-model="filters.merchant_transaction_id"/>
            <v-text-field label="Wallet" v-model="filters.currency"/>
            <v-text-field v-mask="'####-##-##'" placeholder="YYYY/MM/DD" label="Date" v-model="filters.creation_date" />
        </template>
        <template #[`item.creation_date`]="{ item: { raw: item } }">
          <p>{{item.creation_date.replace("T", " ").replace(/\-/g, '/')}}</p>
        </template>
        <template #[`item.currency`]="{ item: { raw: item } }">
          <p>{{item.currency.toUpperCase()}}</p>
        </template>
        <template #[`item.type`]="{ item: { raw: item } }">
          <p>{{item.type.replace("outgoing", "Cash-Out").replace("incoming", "Cash-In")}}</p>
        </template>
    </paginated-datatable>
</template>
<script>
import PaginatedDatatable from "@/components/PaginatedDatatable.vue";
import {mapGetters,mapState} from "vuex";
import {getWallet} from "@/services/wallets-service";
import ExportExcelVue from '@/components/ExportExcel.vue';

export default {
  name: "HomeView",
  components: {PaginatedDatatable, ExportExcelVue},
  computed: {
    ...mapGetters(['isAdmin', 'isSupport', 'isMerchantAdmin']),
    ...mapState(['authUser'])
  },
  async mounted() {
    this.wallets = await getWallet();
    
    document.title = 'Main | CaravelPay'
  },
  data() {
    return {
      // Excel fields
      json_fields:{
        "Data": "creation_date",
        "Merchant Transaction ID": "merchant_transaction_id",
        "Operation Type":"type",
        "Gross Amount":"gross_amount",
        "Net Amount":"net_amount",
        "Balance":"balance_after"
      },
      routeName:'',
      statusOptions:[
        'filled','opened','processing','pending','expired','rejected','canceled'
      ],
      wallets: [],
      openDialog: false,
      headers_merch_adm: [
        {title: 'Date', key: 'creation_date'},
        {title: 'Merchant Transaction ID', key: 'merchant_transaction_id', align: 'start'},
        {title: 'Operation Type', key: 'type', align: 'start'},
        {title: 'Gross Amount', key: 'gross_amount'},
        {title: 'Net Amount', key: 'net_amount'},
        {title: 'Balance', key: 'balance_after'},
        {title: 'Wallet', key: 'currency'},
      ],
      headers: [],
      filters: {},
      items_list: [],
    }
  },
  methods: {
    createWallet() {
      this.$router.push({name: 'create-wallet'});
    },
  }
};
</script>

<style>
.box{
    width: 208px;
    height: 208px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 20px;
    margin-bottom: 50px;
    background-color:#ebebea;
}

.box p {
  margin-left: 15px;
}

.box h1 {
  margin-top: 50px;
  margin-left: 15px;
}

.box img{
  width: 50px;
  height: 50px;
  background-color: #dedede;
  border-radius: 20px;
  margin-top: 15px;
  margin-left: 15px;
}
.icon{
  background-color: #dedede;
  width: 50px;
  height: 50px;
  margin-top: 15px;
  margin-left: 15px;
}

.box:hover {
  background-color: #d3d3d3;
}
</style>
