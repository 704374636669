export default {
    namespaced: true,
    state: {
        selectedUser: undefined,
    },
    getters: {},
    mutations: {
        setSelectedUser(state, user) {
            state.selectedUser = user;
        },
    },
    actions: {
        async selectUser({commit}, user) {
            commit('setSelectedUser', user);
        }
    },
}
