<template>
    <download-excel
        class="btn btn-default"
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        :name="excelFileName +'.xls'"
        @click="loadingData()"
        >
        <v-btn icon="mdi-arrow-up-bold-box-outline" variant="tonal" height="50" width="50px"/>
    </download-excel>
</template>
<script>

export default {
  name: "ExportExcel",
  data() {
    return {
        json_data:[]
    };
  },
  props: {
        json_fields: {
            type: Object,
            default: () => ({}),
        },
        routeName: {
            type: String,
            default:'',
        },
        excelFileName: {
            type: String,
            default:'',
        },
    },
    methods:{
        async loadingData(){
            this.name = this.nameExcel
            const response = await window.axios.get(`${this.routeName}`);
            this.json_data = response.data
        }
    }
    
};
</script>