import {createStore} from 'vuex'
import {AuthUser} from "@/models/user"
import router from "@/router"
import accounts from '@/store/modules/accounts'
import settlements from '@/store/modules/settlements'
import merchantHooks from '@/store/modules/merchant-hooks'
import currency from '@/store/modules/currency'
import wallet from '@/store/modules/wallet'
import bank from '@/store/modules/bank'
import merchant from './modules/merchant'
import merchantADM from './modules/merchant-adm'

export default createStore({
    modules: {
        accounts,
        settlements,
        merchantHooks,
        currency,
        wallet,
        bank,
        merchant,
        merchantADM
    },
    state: {
        authUser: process.env.VUE_APP_BYPASS_AUTH ? new AuthUser() : undefined,
    },
    getters: {
        isSupport(state) {
            return state.authUser && state.authUser.isSupport();
        },
        isMerchantAdmin(state) {
            return state.authUser && state.authUser.isMerchantAdmin();
        },
        isAdmin(state) {
            return state.authUser && state.authUser.isAdmin();
        },
    },
    mutations: {
        setAuthUser(state, user) {
            state.authUser = user;
        },
        SET_MERCHANT(state, newMerchant) {
            state.authUser.merchant = newMerchant;
            //state.authUser = {...state.authUser, merchant: newMerchant};
        },
    },
    actions: {
        async setAuthSession({commit}) {
            const accessToken = await window.auth0.getAccessTokenSilently();
            const idToken = window.auth0.idTokenClaims.value.__raw;
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('id_token', idToken);
            if (idToken) {
                commit('setAuthUser', new AuthUser(accessToken, idToken));
            }
            await router.push({name: 'dashboard'});
        },
        async login() {
            await window.auth0.loginWithRedirect();
        },
        async logout({commit}, redirect = false) {
            await window.auth0
                .logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
                .then(() => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('id_token');
                    commit('setAuthUser', undefined);
                    if (redirect) {
                        router.push({
                            name: 'login',
                            params: {
                                msg: 'Your credentials may have expired. Please revalidate your credentials.'
                            },
                        });
                    }
                });
        },
    },
})
