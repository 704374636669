export default {
    namespaced: true,
    state: {
        selectedMerchantADM: undefined,
    },
    getters: {},
    mutations: {
        setSelectedMerchantADM(state, merchantADM) {
            state.selectedMerchantADM = merchantADM;
        },
    },
    actions: {
        async selectMerchantADM({commit}, merchantADM) {
            commit('setSelectedMerchantADM', merchantADM);
        }
    },
}
