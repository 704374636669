/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import VueTheMask from 'vue-the-mask'
// Composables
import {createApp} from 'vue'

// Plugins
import {registerPlugins} from '@/plugins'

const app = createApp(App)

app.use(VueTheMask)

registerPlugins(app)

app.mount('#app')


