export default {
    namespaced: true,
    state: {
        selectedBank: undefined,
    },
    getters: {},
    mutations: {
        setSelectedBank(state, name) {
            state.selectedBank = name;
        },
    },
    actions: {
        async selectBank({commit}, name) {
            commit('setSelectedBank', name);
        }
    },
}