export async function getMerchant() {
    const response = await window.axios.get(`/merchant`);
    if (!window.axios.validateResponse(response)) {
        return []
    }
    return response.data;
}
export async function getMerchantById(id_merchant){
    const response = await window.axios.get('/merchant/' + id_merchant)
    if (!window.axios.validateResponse(response)) {
        return []
    }
    return response.data;
}

export async function geToken(ref_id){
    const response = await window.axios.get(`/merchant/${ref_id}/renew_api_token`)
    if (!window.axios.validateResponse(response)) {
        return []
    }
    return response;
}

export async function save(userData, editing) {
    if (editing) {
        return await window.axios.patch(`/merchant/${userData.id}`, userData)
    }
    return await window.axios.post(`/merchant`, userData);
}


