// Plugins
import {loadFonts} from './webfontloader'
import vuetify from './vuetify'
import router from '../router'
import store from '../store'
import Auth0Plugin from './auth0'
import AxiosPlugin from './axios'
import ToastPlugin from "./toast";
import JsonExcel from "vue-json-excel3";

export function registerPlugins(app) {
    loadFonts()
    app.use(Auth0Plugin)
        .use(vuetify)
        .use(AxiosPlugin)
        .use(router)
        .use(store)
        .use(ToastPlugin)
        .component("downloadExcel", JsonExcel)
}