<template>
    <v-data-table-server
        hover
        fixed-header
        density='default'
        v-model:items-per-page="itemsPerPage"
        :height="tableHeight"
        :search="search"
        :headers="headers"
        :items-length="total"
        :items="items"
        :loading="loading"
        class="elevation-6 rounded-xl"
        @update:options="reload"
    >
        <template v-if="showToolbar" #[`top`]>
            <v-toolbar class="rounded-t-xl">
                <v-spacer></v-spacer>
                <slot name="toolbar-buttons"/>
                <v-dialog
                    v-if="showSearch"
                    v-model="showFilter"
                    :persistent=false
                    width="780"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn
                            variant="tonal"
                            class=" ml-2"
                            icon='mdi-filter-cog'
                            v-bind="props"
                        />
                    </template>
                    <v-card id="filterField">
                        <v-card-title>
                            <span class="text-h5">Filter</span>
                        </v-card-title>
                        <v-card-text>
                            <slot name="filters" v-bind="filters"/>
                        </v-card-text>
                        <v-card-actions > 
                          <v-spacer></v-spacer>
                            <v-btn
                                color="red-darken-1"
                                variant="text"
                                icon="mdi-close"
                                v-on:click="showFilter = false"
                            />

                            <v-btn
                                color="green-darken-1"
                                variant="text"
                                @click="submit"
                                icon="mdi-check"
                            />

                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-for="(_, name) in $slots" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}"/>
        </template>
        <template v-slot:no-data>
            <span class="empty">Empty result</span>
        </template>
    </v-data-table-server>
    <br>
</template>

<script>
import {VDataTableServer} from 'vuetify/labs/VDataTable'

export default {
  name: "PaginatedDatatable",
  components: {
    VDataTableServer
  },
  props: {
    api_root: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tableHeight() {
      return '440px';
    },
    showToolbar() {
      return !!this.$slots["toolbar-buttons"] || this.showSearch;
    },
    showSearch() {
      return !!this.$slots["filters"];
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      itemsPerPage: 10,
      total: 0,
      items: [],
      showFilter: false,
    }
  },
  methods: {
    async reload({page, itemsPerPage, sortBy}) {
      this.loading = true;
      let orderColumn = 'id';
      let orderType = 'desc';
      if (sortBy.length) {
        orderColumn = sortBy[0].key
        orderType = sortBy[0].order
      }
      let payload = this.$axios.serializeToQueryString({
        page: page,
        size: itemsPerPage,
        order_column: orderColumn,
        order_type: orderType,
      });
      Object.keys(this.filters).forEach(k => [undefined, null, ''].includes(this.filters[k]) && delete this.filters[k] )
      if (Object.keys(this.filters).length) {
        payload = `${payload}&${this.$axios.serializeToQueryString(this.filters)}`;
      }
      const resp = await this.$axios.get(`${this.$axios.apiBaseUrl + this.api_root}/search?${payload}`);
      if (this.$axios.validateResponse(resp)) {
        this.items = resp.data.results || [];
        this.$emit('pass-data', this.items);
        this.total = resp.data.count || 0;
      }
      this.loading = false;
    },
    submit() {
      this.search = String(Date.now());
      this.showFilter = false;
    },
  },
}
</script>

<style scoped>
.empty {
  text-align: center;
  color: gray;
  font-size: larger;
}
#filterField {
  padding: 20px;
  border-radius: 15px;
}
</style>