export default {
    namespaced: true,
    state: {
        selectedSettlement: undefined,
    },
    getters: {},
    mutations: {
        setSelectedSettlement(state, settlements) {
            state.selectedSettlement = settlements;
        },
    },
    actions: {
        async selectSettlement({commit}, settlements) {
            commit('setSelectedSettlement', settlements);
        }
    },
}
