<template>
  <v-app id="inspire">
    <v-app-bar height="150">

      <v-btn @click.stop="drawer = !drawer"  height="70" width="70px" icon>
        <div v-if="drawer === true">
          <v-btn icon="mdi-menu-open" />
        </div>
        <div v-else>  
          <v-img :src="require('@/assets/menu-close.svg')" contain width="25px"/>
        </div>
      </v-btn>
      
      <v-img
          :src="require('@/assets/logo-ligth.png')"
          contain
          height="50"
          max-width="300px"
          class="pa-0 ml-0 iconLogo"
      ></v-img>

      <v-spacer></v-spacer>

      <div v-if="this.isSupport" class="mr-5" height="70" width="70px" icon>
         <p class="manu_popover_header_title">{{merchant_linked}}</p>
      </div>

      <v-btn class="mr-5" height="70" width="70px" icon>
        <v-icon>
          <v-img :src="require('@/assets/bell_notification.svg')" contain width="60px"/>
        </v-icon>
      </v-btn>
      <!-- Icon profile user -->
        <div>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            location="end"
          >
          
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class="mr-5 iconTop" height="70" width="70px" icon @click="toggleArrows">
                <v-icon>
                  <v-img :src="require('@/assets/profile.svg')" width="60px"/>
                  <v-img :src="currentArrowTopImage" v-show="showArrowTop" width="60px"/>
                  <v-img :src="currentArrowBottomImage" v-show="showArrowBottom" width="60px"/>
                </v-icon>
              </v-btn>
            </template>


           
            <v-card min-width="380" class="rounded-xl manu_popover_header" >

              <v-list>

                <v-list-item
                  class="manu_popover_header_subtitle"
                  :title="authUser.email"
                  :subtitle="role"
                >
                </v-list-item>
                <br>

                <v-list-item class="hover-effect " v-if="this.isSupport"  prepend-icon="mdi-account-switch-outline">
                  <v-select
                    density="compact"
                    v-model="selected_merchats"
                    :items="merchants_list"
                    item-title="name" 
                    label="Switch Merchant*"
                    variant="underlined"
                    return-object
                    single-line
                  ></v-select>
                </v-list-item>

                <v-list-item  prepend-icon="mdi-cog" title="Settings" value="main"/>

                <v-list-item  prepend-icon="mdi-help-circle-outline" title="Help" value="main"/>

                <v-list-item  prepend-icon="mdi-logout" title="Logout" @click="logout" value="main"/>
                
              </v-list>
              
            </v-card>
          </v-menu>
        </div>
      <!-- End Icon profile user -->
    </v-app-bar>
    <!-- ok -->
    <v-navigation-drawer
        border="none"
        class="bg-grey-lighten-5"
        v-model="drawer"
        :permanent=true
    >
      <v-divider></v-divider>

      <v-list density="compact">
        <v-list-item :exact=true prepend-icon="mdi-home" title="Main" value="main" to="/"/>
        <v-list-item :exact=true prepend-icon="mdi-wallet-bifold" title="Bank/Crypto Accounts" value="bankcryptoaccounts" to="/bankcryptoaccounts/"/>
        <v-list-item
            v-if="isSupport"
            :exact=true
            prepend-icon="mdi-hand-coin"
            title="Currencies"
            value="currency"
            to="/currencies/"
        />
        <v-list-item v-if="isAdmin" :exact=true prepend-icon="mdi-handshake" title="Merchants" value="merchants" to="/merchants/"/>
        <v-list-item v-if="!this.isSupport" :exact=true prepend-icon="mdi-account-cash-outline" title="Manual Cash-Outs" value="manualcashouts" to="/manualcashouts/"/>
        <v-list-item :exact=true prepend-icon="mdi-bank" title="Top Ups / Settlements" value="settlements" to="/topupssettlements/"/>
        <v-list-item :exact=true prepend-icon="mdi-account-cog" title="Users" value="accounts" to="/users/"/>
        <v-list-item :exact=true prepend-icon="mdi-webhook" title="Webhooks" value="webhooks" to="/webhooks/"/>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg-grey-lighten-4" >
      <div v-if="update_screen === true">
        <v-container id="main-content">
          <router-view/>
        </v-container>
      </div>
      <div v-else>
        <v-container>
          <router-view/>
        </v-container>
      </div>
    </v-main>

  </v-app>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { getMerchantById, getMerchant } from "@/services/merchants-service";

export default {
  name: "DashboardView",
  async mounted(){
    this.role = this.authUser.roles[0]
    this.role = this.role.replace('_', ' ')
    this.role =  this.role.toUpperCase()

    if (this.authUser.roles[0]=='caravel_admin' || this.authUser.roles[0]=='caravel_support'){
      this.show = true
      const response = await getMerchantById(this.authUser.merchant.merchant_id)
      this.merchant_linked = response.name
    }
     
    if(this.isSupport){
      let loading_merchants = await getMerchant();
      let all_merchants = loading_merchants.map(item => item)
      this.merchants_list = all_merchants
    }
  },
  computed: {
    ...mapGetters(['isSupport', 'isAdmin']),
    ...mapState(['authUser']),
    
    currentArrowTopImage() {
      return this.showArrowTop ? require('@/assets/arrow_top.svg') : null;
    },
    currentArrowBottomImage() {
      return this.showArrowBottom ? require('@/assets/arrow_bottom.svg') : null;
    },
  },
  data: () => ({
    showArrowTop: true,
    showArrowBottom: false,
    update_screen:true,
    drawer: true,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    role: null,
    merchant_linked: null,
    show: false,
    merchants_list: [],
    selected_merchats: null,
  }),
   watch: {
    selected_merchats(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.apply_merchant();
      }
    },
  },
  methods: {
    ...mapActions('merchant', ['selectMerchant']),
    ...mapActions(['logout']),

    // Apply the merchant and id to the administrator user
    apply_merchant(){
        //Sending the merchant ID to the session
        let novoValorDoMerchant = {merchant_id : this.selected_merchats.id}
        this.$store.commit('SET_MERCHANT', novoValorDoMerchant);
        //Sending the merchant token to the session
        this.selectMerchant(this.selected_merchats.api_token)

        this.update_screen = !this.update_screen
        this.merchant_linked = this.selected_merchats.name
        this.menu = false
        this.$toast.warning("You are managing the "+this.selected_merchats.name +" merchant");
    },
    toggleArrows() {
      this.showArrowTop = !this.showArrowTop;
      this.showArrowBottom = !this.showArrowBottom;
      // Outras lógicas, se necessário...
    },
  },
}
</script>

<style>

.manu_popover_header_title{
  font-size: 25px;
  text-align: center;
}
.manu_popover_header_subtitle{
  font-size: 17px;
  
}
.manu_popover_header{
  margin-top: 60px;
  padding: 20px 25px 20px 25px;
}
#main-content{
  margin-left: 15px;
}
@media (max-width: 380px) {
  .iconLogo {
    display: none;
  }
}

.hover-effect {
  height: 45px;
}

.hover-effect:hover {
  background-color: #f2f2f2;
}

</style>