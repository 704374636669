export async function getWallet() {
    const response = await window.axios.get(`/wallet/by_auth`);
    if (!window.axios.validateResponse(response)) {
        return []
    }
    return response.data;
}

export async function createWallet(userData, editing = false) {
    if (editing) {
        return await window.axios.patch(`/wallet/${userData.id}`, userData)
    }
    return await window.axios.post(`/wallet`, userData);
}

export async function deleteWallet(userData) {
    return await window.axios.delete(`/wallet/${userData.id}`)
}