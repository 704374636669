/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    components,
    directives,
    theme: {
        themes: {
            light: {
                colors: {
                    primary: '#28285E',
                    secondary: '#2F3C90',
                    background: '#558FCC',
                    surface: '#FFFFFF',
                    info: '#558FCC',
                },
            },
        },
    },
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    ssr: true,
})