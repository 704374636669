<template>
  <v-container id="login-view">
    <v-row align="center" justify="center" class="background" dense>
      <v-col cols="12"
             sm="8"
             md="6"
             class="d-flex flex-column justify-center align-center"
      >
        <v-progress-circular v-if="loggedIn" color="primary" indeterminate size="64"/>
        <v-card v-else>
          <v-card-title>
            <v-layout row wrap align-center>
              <v-img :src="require('@/assets/logo_caravel.png')" contain height="90"/>
            </v-layout>
          </v-card-title>
          <v-card-subtitle class="text-center">
            You are at CaravelPay Merchant's Administrative App.
            <hr>
          </v-card-subtitle>
          <v-card-text>
            <v-alert color="info" class="text-justify">
              <p>
                This is your Merchant's payment administrative panel to configure
                and manage your CaravelPay integrations, workflows and wallet stuff.
              </p>
              <br>
              <p>
                In order to proceed you must have a valid Merchant Profile and user credentials.
              </p>
              <br>
              <p>
                If you are not a registered customer please contact our sales at
                <a href="https://caravelpay.com" target="_blank">website</a> to require your access.
              </p>
            </v-alert>
          </v-card-text>
          <v-card-item class="footer">
            <v-btn color="primary" @click="login">Proceed to login...</v-btn>
          </v-card-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "LoginView",
  head() {
    return {
      title: 'Login | Caravel'
    }
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  methods: {
    ...mapActions(['login', 'setAuthSession']),
  },
  async created() {
    if (this.$route.query.code) {
      this.loggedIn = true;
      this.setAuthSession();
    }
  },
};
</script>
<style>
html, body {
  color: rgba(40, 40, 94, 0.6);
  background-color: #558FCC;
}

.background {
  height: calc(100vh - 2rem);
}
</style>
<style scoped>
:deep(.v-card-title) {
  margin: 2rem 0 1rem 0;
}

:deep(.footer) {
  padding: 2rem 0;
  text-align: center;
}

hr {
  margin: 1rem 0;
}
</style>