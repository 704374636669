export class AuthUser {
    constructor(accessToken, idToken) {
        this.bypass = process.env.VUE_APP_BYPASS_AUTH === 'true';
        let accessTokenPayload = {};
        let idTokenPayload = {};
        if (!this.bypass && accessToken && idToken) {
            accessTokenPayload = JSON.parse(
                decodeURIComponent(
                    this.encodeBase64(accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))
                )
            );
            idTokenPayload = JSON.parse(
                decodeURIComponent(
                    this.encodeBase64(
                        idToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
                    )
                )
            );
        }
        this.email = idTokenPayload['email'];
        this.name = idTokenPayload['name'];
        this.nickname = idTokenPayload['nickname'];
        this.username = idTokenPayload['username'];
        this.merchant = idTokenPayload['user.meta'];
        this.identities = idTokenPayload['user.identities'] || [];
        this.roles = idTokenPayload['user.roles'] || [];
        this.permissions = accessTokenPayload['permissions'] || [];
    }

    encodeBase64(base64) {
        return window.atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    }

    isAdmin() {
        return this.bypass
            || (this.roles.includes('caravel_admin'))
            || (this.permissions.includes('read:all') && this.permissions.includes('write:all'));
    }

    isSupport() {
        return this.isAdmin()
            || (this.roles.includes('caravel_support'))
            || (this.permissions.includes('read:support') && this.permissions.includes('write:support'));

    }

    isMerchantAdmin() {
        return this.isAdmin()
            || (this.roles.includes('merchant_admin'))
            || (this.permissions.includes('read:merchant') && this.permissions.includes('write:merchant'));

    }
}