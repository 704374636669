<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {}
</script>
<style>
</style>