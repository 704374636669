export default {
    namespaced: true,
    state: {
        selectedCurrency: undefined,
    },
    getters: {},
    mutations: {
        setSelectedCurrency(state, name) {
            state.selectedCurrency = name;
        },
    },
    actions: {
        async selectCurrency({commit}, name) {
            commit('setSelectedCurrency', name);
        }
    },
}
  