import {createAuth0} from '@auth0/auth0-vue';

const Auth0Plugin = {
    install: (app) => {
        app.use(
            createAuth0({
                domain: "https://caravelpay.us.auth0.com",
                clientId: "2puTAQdN50HEhaHWDPvWHve5uJWd5TMY",
                authorizationParams: {
                    redirect_uri: `${window.location.origin}/login`,
                    audience: 'caravel-pay',
                }
            })
        );
        window.auth0 = app.config.globalProperties['$auth0']
    },
};

export default Auth0Plugin;