export default {
    namespaced: true,
    state: {
        selectedWallet: undefined,
    },
    getters: {},
    mutations: {
        setSelectedWallet(state, wallet) {
            state.selectedWallet = wallet;
        },
    },
    actions: {
        async selectWallet({commit}, wallet) {
            commit('setSelectedWallet', wallet);
        },
    },
}