export default {
    namespaced: true,
    state: {
        selectedMerchant: undefined,
    },
    getters: {},
    mutations: {
        setSelectedMerchant(state, name) {
            state.selectedMerchant = name;
        },
    },
    actions: {
        async selectMerchant({commit}, name) {
            commit('setSelectedMerchant', name);
        }
    },
}
