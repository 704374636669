import axios from 'axios'
import store from '@/store/index'

// Full config:  https://github.com/axios/axios#request-config
const config = {
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60 * 1000,
    withCredentials: false,
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
    (cfg) => {
        let access_token = localStorage.getItem('access_token') || '';
        let id_token = localStorage.getItem('id_token') || '';

        if (!access_token && process.env.VUE_APP_BYPASS_AUTH) {
            access_token = 'Auha88daHADuahwDQ0ZD877siDii8dabASJ7DSY6GSD4S65DADg9s'
        }
        // Will test the user role to apply the managed merchant token
        if  (store.state.authUser.roles == "caravel_admin" && store.state.merchant.selectedMerchant != undefined){
            access_token = store.state.merchant.selectedMerchant
            console.log("USUARIO DA REQUEST",access_token)
        }
        
        if (access_token) {
            cfg = {
                ...cfg,
                ...{
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'x-id-token': id_token,
                    }
                },
            };
        }
        return cfg;
    },
    (err) => {
        return Promise.reject(err);
    },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (res) => {
        // Do something with response data
        return res.data;
    },
    (err) => {
        if (err.response.status === 401) {
            if (process.env.VUE_APP_BYPASS_AUTH !== 'true') {
                store.dispatch('logout', { redirect: true })
            }
        }
        axiosInstance.validateResponse(err.response);
        return Promise.reject(err);
    },
);

axiosInstance.apiBaseUrl = config.baseURL;

axiosInstance.validateResponse = (response) => {
    if (!response || !response.success) {
        if (!response.msg) {
            if(response.data.detail){
                window.toast.error(response.data.detail);
            }
            else{
                window.toast.error('Unknown error! Please try again in a moment...');
            }
            return false;
        }
        window.toast.warning(response.msg);
        return false;
    }
    return true;
};

axiosInstance.serializeToQueryString = function (obj, rootName, ignoreList) {
    let params = [];
    const appendFormData = (data, root) => {
        if (ignore(root)) {
            return;
        }
        root = root || '';
        if (Array.isArray(data)) {
            data.forEach((data, index) => appendFormData(data, `${root}[${index}]`));
        } else if (typeof data === 'object' && data) {
            Object.keys(data).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    if (root === '') {
                        appendFormData(data[key], key);
                    } else {
                        appendFormData(data[key], `${root}.${key}`);
                    }
                }
            });
        } else {
            if (data !== null && typeof data !== 'undefined') {
                params.push(`${encodeURIComponent(root)}=${encodeURIComponent(data)}`);
            }
        }
    };
    const ignore = root => Array.isArray(ignoreList) && ignoreList.some(x => x === root);
    appendFormData(obj, rootName);
    return params.join('&');
};

const AxiosPlugin = {
    install: (app) => {
        app.config.globalProperties.$axios = axiosInstance;
        window.axios = axiosInstance;
    },
};

export default AxiosPlugin;

